<template>
 <div class="img-table">
    <avue-crud v-bind="bindVal"
        v-on="onEvent"
        v-model="form"
        :page.sync="page">
      <template #menu="{row}" >
        <el-button
            type="text"
            icon="el-icon-check"
          @click="onFuccheck(row)"
          >查看图片</el-button
        >
        <el-button
            type="text"
            icon="el-icon-delete"
          @click="onFucDel(row)"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <basic-dialog
        ref="dialog"
        title="查看图片"
        :isMaskClose="true"
        width="60%"
      >
      <div class="dialog_check_btn">
         <el-button
            type="primary"
            size="mini"
            @click="downloadCodeImg(imgData && imgData.tempUrl)"
            >下载图片</el-button
          >
      </div>
      <el-image
      :src="imgData && imgData.tempUrl"
      ></el-image>
    </basic-dialog>
 </div>
</template>

<script>
  import { sendRecordimg } from "@/api/img/index"
let crud = window.$crudCommon({}, {
  name: "img/index",
  pageNumber: 'current',//页码
  pageSize: 'size',//页数
  total: 'total',//总页数
  res:(data)=>{
    return {
      total: data.total,
      data: data.records
    }
  },//列表的结构
})

import { checkimg, del } from "@/api/img/index"
export default {
  name: 'imgTable',
  components: {
  },
  mixins: [crud],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
  },
  data () {
    return {
      imgData: null,
      picId: null
    }
  },
  watch: {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    // 查看图片
    onFuccheck(row) {
        this.picId = row.picId
        checkimg(row.picId).then(res => {
          if (res.data) {
            this.imgData = res.data
            // console.log('', JSON.parse(res.data.annotation));
            this.$refs.dialog.show()
          }
        })
    },
    onFucDel(row) {
      this.picId = row.picId
      this.$prompt('请输入备注', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          del({
            picId: row.picId,
            remark: value
          }).then(res => {
            if (res.data) {
              this.$message.success('删除成功')
              this.onEvent['refresh-change']()
            }
          })
        }).catch(() => {
        });
    },
       //列表前操作方法
    listBefore() {
      this.params.province = '四川'
      if (!this.params.picType) {
        this.params.picTypeList = [1, 3]
      } else {
        this.params.picTypeList = this.params.picType
      }
    },
    downloadCodeImg(Img) {
      sendRecordimg(this.picId).then(res => {
        if (res.data) {
          var image = new Image();
          image.setAttribute("crossOrigin", "anonymous");
          var _this = this;
          image.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            var context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);
            var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download = _this.projectName || "photo"; // 设置图片名称
            a.href = url; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
          };
          image.src = Img;
        }
      })
    },
  }
}
</script>

<style>
  .img-table {
    padding: 25px;
  }
  .avue-group{
    padding-top: 10px;
  }
  .avue-crud__menu {
    padding: 10px;
  }
  .el-button--primary {
    background-color: #e94e46;
    border-color: #e94e46;
  }
  .el-button--primary:focus, .el-button--primary:hover {
    background-color: #e94e46;
    border-color: #e94e46;
    opacity: 0.7;
  }
  .el-button--text {
    color: #e94e46;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #e94e46;
  }
  /* .ui-model .el-drawer__body .avue-group .el-collapse-item__content .el-collapse-item__content .avue-crud__menu, .avue-form__group {
    background: white !important;
  } */
  /* .el-card__body {
    background: #0f1325 !important;
  }
  .role-table .avue-crud .avue-crud__menu {
    background: #0f1325 !important;
  }
  .role-table .avue-crud .avue-form__group {
    background: #0f1325 !important;
  }
  .avue-crud .el-table th {
    background: #0f1325 !important;
    color: #d3d6dd;
  }
  .el-collapse-item__wrap {
    border: none !important;
  }
  .el-collapse {
    border-top: none !important;
  }
  .el-table tr , td {
    background: #0f1325 !important;
    color: #d3d6dd;
  }
  .el-card {
    background: #0f1325 !important;
  } */
  
  /* .el-table, .el-table__expanded-cell {
    background: #0f1325 !important;
  } */
  /* .el-table tr{
      margin: 0;
      padding: 0;
      height: 50px;
  }
  .el-table thead tr{
      background: rgba(5, 58, 152, 0.5);
  }
  .el-table tbody .row-class-fix td:first-child{
      border-left: 1px solid #053A98;
  }
  .el-table td{
      border-bottom: 1px solid #053A98;
      border-right: 1px solid #053A98;
  } */
  .dialog_check_btn {
    margin-bottom: 15px;
    text-align: right;
  }
</style>
